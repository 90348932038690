<ng-container *ngIf="enrollment$ | ngrxPush as enrollment">
    <form
        [formGroup]="registrationFormGroup"
        (submit)="trySubmit($event)"
        *ngrxLet="isProcessing$ as isProcessing"
        dtmUiInvalidFormScrollable
    >
        <div class="overflow-container scroll-shadows">
            <h2 class="header">{{ "dtmLpLibEnrollment.confirmationFormStep.createAccountText" | transloco }}</h2>
            <dtm-ui-input-field>
                <label>{{ "dtmLpLibEnrollment.confirmationFormStep.firstNameInputLabel" | transloco }}</label>
                <input matInput type="text" formControlName="firstName" autocomplete="given-name" data-testid="first-name-input" />
                <div class="field-error" *dtmUiFieldHasError="firstNameFormControl; name: ['required', 'pattern']">
                    {{ "dtmLpLibEnrollment.requiredFieldErrorHint" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="firstNameFormControl; name: 'maxlength'; error as error">
                    {{ "dtmLpLibEnrollment.confirmationFormStep.firstNameMaxLengthErrorHint" | transloco : { max: error.requiredLength } }}
                </div>
            </dtm-ui-input-field>
            <dtm-ui-input-field>
                <label>{{ "dtmLpLibEnrollment.confirmationFormStep.lastNameInputLabel" | transloco }}</label>
                <input matInput type="text" formControlName="lastName" autocomplete="family-name" data-testid="last-name-input" />
                <div class="field-error" *dtmUiFieldHasError="lastNameFormControl; name: ['required', 'pattern']">
                    {{ "dtmLpLibEnrollment.requiredFieldErrorHint" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="lastNameFormControl; name: 'maxlength'; error as error">
                    {{ "dtmLpLibEnrollment.confirmationFormStep.lastNameMaxLengthErrorHint" | transloco : { max: error.requiredLength } }}
                </div>
            </dtm-ui-input-field>
            <dtm-ui-country-select-field [formControl]="nationalityFormControl" [suggestedValue]="NATIONALITY_SUGGESTED_VALUE">
                <label>{{ "dtmLpLibEnrollment.confirmationFormStep.nationalityInputLabel" | transloco }}</label>
                <div class="field-error" *dtmUiFieldHasError="nationalityFormControl; name: 'required'">
                    {{ "dtmLpLibEnrollment.requiredFieldErrorHint" | transloco }}
                </div>
            </dtm-ui-country-select-field>
            <dtm-ui-date-field>
                <label>{{ "dtmLpLibEnrollment.confirmationFormStep.dateOfBirthInputLabel" | transloco }}</label>
                <input
                    *ngrxLet="datePickerPlaceholder$ as datePickerPlaceholder"
                    [formControl]="dateOfBirthFormControl"
                    matInput
                    [matDatepicker]="dateFromDatepicker"
                    [max]="dateOfBirthStartDate"
                    [min]="MIN_DATE_OF_BIRTH"
                    [placeholder]="datePickerPlaceholder"
                />
                <mat-datepicker #dateFromDatepicker></mat-datepicker>
                <div class="field-error" *dtmUiFieldHasError="dateOfBirthFormControl; name: 'required'">
                    {{ "dtmLpLibEnrollment.requiredFieldErrorHint" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="dateOfBirthFormControl; name: 'matDatepickerMax'">
                    {{ "dtmLpLibEnrollment.confirmationFormStep.minAgeErrorHint" | transloco : { minAge: MIN_USER_AGE } }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="dateOfBirthFormControl; name: 'matDatepickerMin'; error as error">
                    {{
                        "dtmLpLibEnrollment.confirmationFormStep.minDateOfBirthErrorHint"
                            | transloco : { minDate: error.min | localizeDate }
                    }}
                </div>
            </dtm-ui-date-field>

            <dtm-ui-input-field [isClearable]="false">
                <label>{{ "dtmLpLibEnrollment.confirmationFormStep.passwordInputLabel" | transloco }}</label>
                <input
                    #passwordInput
                    matInput
                    type="password"
                    [formControl]="passwordFormControl"
                    autocomplete="new-password"
                    data-testid="password-input"
                />
                <dtm-ui-password-visibility-switch [inputParent]="passwordInput" class="field-suffix"> </dtm-ui-password-visibility-switch>

                <div class="field-hint">
                    {{ "dtmLpLibEnrollment.confirmationFormStep.passwordComplexityHint" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="passwordFormControl; name: 'pattern'">
                    {{ "dtmLpLibEnrollment.confirmationFormStep.passwordComplexityHint" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="passwordFormControl; name: 'required'">
                    {{ "dtmLpLibEnrollment.requiredFieldErrorHint" | transloco }}
                </div>
            </dtm-ui-input-field>
            <dtm-ui-input-field [isClearable]="false">
                <label>{{ "dtmLpLibEnrollment.confirmationFormStep.passwordRepeatInputLabel" | transloco }}</label>
                <input
                    #passwordRepeat
                    matInput
                    type="password"
                    [formControl]="passwordRepeatFormControl"
                    autocomplete="new-password"
                    data-testid="repeat-password-input"
                />
                <dtm-ui-password-visibility-switch [inputParent]="passwordRepeat" class="field-suffix"></dtm-ui-password-visibility-switch>
                <div class="field-error" *dtmUiFieldHasError="passwordFormGroup; name: 'passwordMatch'">
                    {{ "dtmLpLibEnrollment.confirmationFormStep.passwordMatchErrorHint" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="passwordRepeatFormControl; name: 'required'">
                    {{ "dtmLpLibEnrollment.requiredFieldErrorHint" | transloco }}
                </div>
            </dtm-ui-input-field>
            <dtm-ui-input-field>
                <label>{{ "dtmLpLibEnrollment.confirmationFormStep.confirmationCodeInputLabel" | transloco }}</label>
                <input
                    matInput
                    type="text"
                    [formControl]="confirmationCodeFormControl"
                    autocomplete="off"
                    data-testid="confirmation-code-input"
                />
                <div class="field-error" *dtmUiFieldHasError="confirmationCodeFormControl; name: 'required'">
                    {{ "dtmLpLibEnrollment.requiredFieldErrorHint" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="confirmationCodeFormControl; name: 'invalid'">
                    {{ "dtmLpLibEnrollment.confirmationFormStep.invalidConfirmationCodeErrorHint" | transloco }}
                </div>
            </dtm-ui-input-field>
        </div>
        <div class="buttons">
            <button
                type="submit"
                [ngClass]="{ processing: isProcessing }"
                class="button-primary"
                data-testid="submit-confirmation-registration-form-button"
            >
                <mat-spinner diameter="20" *ngIf="isProcessing"></mat-spinner>
                <span>
                    {{ "dtmLpLibEnrollment.confirmationFormStep.registerButtonLabel" | transloco }}
                </span>
            </button>

            <div class="resend-container">
                <p class="no-confirmation-text">
                    {{ "dtmLpLibEnrollment.confirmationFormStep.noConfirmationCodeReceivedLinkText" | transloco }}
                </p>
                <button class="button-link" (click)="noConfirmationCodeRedirect.emit()" type="button">
                    {{ "dtmLpLibEnrollment.confirmationFormStep.resendSMSLabel" | transloco }}
                </button>
            </div>
        </div>
    </form>
</ng-container>
