import { createDynamicEnvironment } from "@dtm-frontend/shared/utils";
import type DynamicConfiguation from "../assets/dynamic.config.json";
import { LandingPageEnvironment } from "./environment.model";

export const dynamicEnvironment = createDynamicEnvironment<LandingPageEnvironment, typeof DynamicConfiguation>(
    fetch("../assets/dynamic.config.json").then((config) => config.json()),
    ({ apiDomain, keycloakConfig, loginPageUrl }) => {
        const apiUrl = `https://${apiDomain}/api`;

        return {
            production: false,
            name: "dev",
            bearerExcludedUrls: ["users/enrollments"],
            loginPageUrl,
            keycloakConfig,
            endpoints: {
                getCapabilities: `${apiUrl}/users/capabilities`,
                createEnrollment: `${apiUrl}/users/enrollments`,
                updateEnrollment: `${apiUrl}/users/enrollments/{{id}}`,
                confirmEnrollment: `${apiUrl}/users/enrollments/{{id}}/confirmation`,
                resendConfirmationEmail: `${apiUrl}/users/enrollments/{{id}}/confirmation-email`,
                resendConfirmationCode: `${apiUrl}/users/enrollments/{{id}}/confirmation-sms`,
                createUser: `${apiUrl}/users/enrollments/{{id}}/personal-data`,
                termsOfUse: `${apiUrl}/terms-of-service/uav-app/current`,
            },
            routes: {
                faqPageUrl: "https://ulc.gov.pl/pl/drony/czesto-zadawane-pytania-faq",
                uavRulesPageUrl: "https://ulc.gov.pl/pl/drony",
                accessibilityStatementUrl: `${apiUrl}/accessibility-statement`,
            },
            versionDataEndpoints: {
                getVersionDetails: `${apiUrl}/system/service-info`,
            },
        };
    }
);
