import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { PASSWORD_PATTERN } from "@dtm-frontend/shared/auth";
import { Alpha3CountryCode, InvalidFormScrollableDirective } from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import {
    DEFAULT_COUNTRY_CODE,
    LocalComponentStore,
    MIN_DATE_OF_BIRTH,
    MIN_USER_AGE,
    ONLY_WHITE_SPACES_VALIDATION_PATTERN,
} from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { tap } from "rxjs/operators";
import { Enrollment, EnrollmentError, EnrollmentErrorType } from "../../../services/models";

interface ConfirmationWizardConfirmationFormStepComponentState {
    isProcessing: boolean;
    registrationError: EnrollmentError | undefined;
    enrollment: Enrollment | undefined;
}

export interface RegistrationFormData {
    password: string;
    confirmationCode: string;
    enrollmentId: string;
    firstName: string;
    lastName: string;
    nationality: Alpha3CountryCode;
    dateOfBirth: string;
}

interface RegistrationForm {
    passwordGroup: FormGroup<PasswordFormGroup>;
    confirmationCode: FormControl<string>;
    firstName: FormControl<string>;
    lastName: FormControl<string>;
    nationality: FormControl<Alpha3CountryCode>;
    dateOfBirth: FormControl<string>;
}

interface PasswordFormGroup {
    password: FormControl<string>;
    passwordRepeat: FormControl<string>;
}

const MAX_USER_NAME_LENGTH = 100;

@UntilDestroy()
@Component({
    selector: "dtm-lp-lib-confirmation-wizard-confirmation-form-step-component",
    templateUrl: "./confirmation-form-step.component.html",
    styleUrls: ["./confirmation-form-step.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ConfirmationWizardConfirmationFormStepComponent {
    @Output() public readonly registrationComplete = new EventEmitter<RegistrationFormData>();
    @Output() public readonly noConfirmationCodeRedirect = new EventEmitter();
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set registrationError(value: EnrollmentError | undefined) {
        this.localStore.patchState({ registrationError: value });
    }
    @Input() public set enrollment(value: Enrollment | undefined) {
        this.localStore.patchState({ enrollment: value });
    }

    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly enrollment$ = this.localStore.selectByKey("enrollment");

    protected readonly passwordFormControl = new FormControl<string>("", {
        validators: [Validators.pattern(PASSWORD_PATTERN), Validators.required],
        nonNullable: true,
        updateOn: "blur",
    });
    protected readonly passwordRepeatFormControl = new FormControl<string>("", {
        validators: [Validators.required],
        nonNullable: true,
        updateOn: "blur",
    });
    protected readonly confirmationCodeFormControl = new FormControl<string>("", {
        validators: Validators.required,
        nonNullable: true,
    });
    protected readonly firstNameFormControl = new FormControl<string>("", {
        validators: [
            Validators.required,
            Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
            Validators.maxLength(MAX_USER_NAME_LENGTH),
        ],
        nonNullable: true,
    });
    protected readonly lastNameFormControl = new FormControl<string>("", {
        validators: [
            Validators.required,
            Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
            Validators.maxLength(MAX_USER_NAME_LENGTH),
        ],
        nonNullable: true,
    });
    protected readonly nationalityFormControl = new FormControl<Alpha3CountryCode>(DEFAULT_COUNTRY_CODE, {
        validators: Validators.required,
        nonNullable: true,
    });
    protected readonly dateOfBirthFormControl = new FormControl<string>("", {
        validators: [Validators.required, this.dateGreaterThanValidator()],
        nonNullable: true,
    });
    protected readonly dateOfBirthStartDate: Date = new Date();
    protected readonly passwordFormGroup: FormGroup<PasswordFormGroup> = new FormGroup<PasswordFormGroup>(
        {
            password: this.passwordFormControl,
            passwordRepeat: this.passwordRepeatFormControl,
        },
        { validators: this.passwordMatchValidator() }
    );
    protected readonly registrationFormGroup = new FormGroup<RegistrationForm>({
        passwordGroup: this.passwordFormGroup,
        confirmationCode: this.confirmationCodeFormControl,
        firstName: this.firstNameFormControl,
        lastName: this.lastNameFormControl,
        nationality: this.nationalityFormControl,
        dateOfBirth: this.dateOfBirthFormControl,
    });

    protected readonly MIN_USER_AGE = MIN_USER_AGE;
    protected readonly MIN_DATE_OF_BIRTH = MIN_DATE_OF_BIRTH;
    protected readonly datePickerPlaceholder$ = this.translocoHelper.datePickerPlaceholder$;

    constructor(
        private readonly localStore: LocalComponentStore<ConfirmationWizardConfirmationFormStepComponentState>,
        private readonly translocoHelper: TranslationHelperService
    ) {
        this.localStore.setState({ isProcessing: false, registrationError: undefined, enrollment: undefined });

        this.initializeDateOfBirthMaxValue();

        this.isProcessing$
            .pipe(
                tap((isProcessing) => (isProcessing ? this.registrationFormGroup.disable() : this.registrationFormGroup.enable())),
                untilDestroyed(this)
            )
            .subscribe();

        this.localStore
            .selectByKey("registrationError")
            .pipe(
                tap((error) => {
                    if (error?.type === EnrollmentErrorType.InvalidConfirmationCode) {
                        this.confirmationCodeFormControl.setErrors({
                            invalid: true,
                        });
                        this.confirmationCodeFormControl.markAsTouched();
                    }
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private passwordMatchValidator(): ValidatorFn {
        return (formGroup: AbstractControl): ValidationErrors | null => {
            const control = formGroup.get("password");
            const matchingControl = formGroup.get("passwordRepeat");
            if (control?.errors || matchingControl?.errors) {
                return null;
            }

            return control?.value === matchingControl?.value ? null : { passwordMatch: true };
        };
    }

    private dateGreaterThanValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.errors) {
                return null;
            }

            return new Date(control.value) < this.dateOfBirthStartDate ? null : { minimumAge: true };
        };
    }

    private initializeDateOfBirthMaxValue(): void {
        this.dateOfBirthStartDate.setFullYear(this.dateOfBirthStartDate.getFullYear() - MIN_USER_AGE);
    }

    protected trySubmit(event: Event): void {
        event.stopPropagation();
        event.preventDefault();
        event.stopImmediatePropagation();

        const isProcessing = this.localStore.selectSnapshotByKey("isProcessing");
        const enrollmentId = this.localStore.selectSnapshotByKey("enrollment")?.id;

        if (this.registrationFormGroup.invalid || isProcessing) {
            this.registrationFormGroup.markAllAsTouched();
            this.invalidFormScrollable.scrollToFirstInvalidField();

            return;
        }

        this.registrationComplete.emit({
            enrollmentId: enrollmentId ?? "",
            dateOfBirth: this.dateOfBirthFormControl.value,
            confirmationCode: this.confirmationCodeFormControl.value,
            firstName: this.firstNameFormControl.value,
            lastName: this.lastNameFormControl.value,
            nationality: this.nationalityFormControl.value,
            password: this.passwordFormControl.value,
        });
    }
}
