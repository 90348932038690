import { InjectionToken } from "@angular/core";

export interface EnrollmentEndpoints {
    getCapabilities: string;
    createEnrollment: string;
    updateEnrollment: string;
    confirmEnrollment: string;
    resendConfirmationCode: string;
    resendConfirmationEmail: string;
    createUser: string;
    termsOfUse: string;
}
export const ENROLLMENT_ENDPOINTS = new InjectionToken<EnrollmentEndpoints>("Enrollment endpoints");
