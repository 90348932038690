import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, MIN_USER_AGE, PREVIOUS_MIN_USER_AGE } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { map, tap } from "rxjs/operators";
import { EnrollmentData, EnrollmentFeatures } from "../../../services/models";
import { EnrollmentActions } from "../../../state/enrollment.actions";
import { EnrollmentState } from "../../../state/enrollment.state";

export enum EnrollmentWizardStep {
    EnrollmentForm = "EnrollmentForm",
    SuccessMessage = "SuccessMessage",
    ResendingSuccessMessage = "ResendingSuccessMessage",
    ResendingProgressMessage = "ResendingProgressMessage",
    SendingError = "SendingError",
}

interface EnrollmentWizardComponentState {
    currentStep: EnrollmentWizardStep;
}

@UntilDestroy()
@Component({
    selector: "dtm-lp-lib-enrollment-wizard",
    templateUrl: "./enrollment-wizard.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class EnrollmentWizardComponent {
    @Input() public set currentStep(value: EnrollmentWizardStep) {
        this.localStore.patchState({ currentStep: value });
    }

    public EnrollmentWizardStep = EnrollmentWizardStep;

    public readonly currentStep$ = this.localStore.selectByKey("currentStep");
    public readonly isProcessing$ = this.store.select(EnrollmentState.isProcessing);
    public readonly enrollment$ = this.store.select(EnrollmentState.enrollment);
    public readonly enrollmentError$ = this.store.select(EnrollmentState.error);
    public readonly minUserAge$ = this.store
        .select(EnrollmentState.isFeatureAvailable(EnrollmentFeatures.AviationLav2025))
        .pipe(map((isFeatureAvailable) => (isFeatureAvailable ? MIN_USER_AGE : PREVIOUS_MIN_USER_AGE)));

    constructor(private readonly localStore: LocalComponentStore<EnrollmentWizardComponentState>, private readonly store: Store) {
        this.localStore.setState({
            currentStep: EnrollmentWizardStep.EnrollmentForm,
        });
    }

    public goToStep(newStep: EnrollmentWizardStep) {
        this.localStore.patchState({ currentStep: newStep });
    }

    public enroll(enrollmentData: EnrollmentData) {
        this.store
            .dispatch(new EnrollmentActions.Enroll(enrollmentData))
            .pipe(
                tap(() => {
                    const error = this.store.selectSnapshot(EnrollmentState.error);
                    const enrollment = this.store.selectSnapshot(EnrollmentState.enrollment);

                    if (enrollment && !error) {
                        this.goToStep(EnrollmentWizardStep.SuccessMessage);

                        return;
                    }

                    this.goToStep(EnrollmentWizardStep.SendingError);
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    public login(): void {
        this.store.dispatch(new EnrollmentActions.GoToLoginPage());
    }

    public resendEmail() {
        this.localStore.patchState({ currentStep: EnrollmentWizardStep.ResendingProgressMessage });

        this.store
            .dispatch(new EnrollmentActions.ResendConfirmationEmail())
            .pipe(
                tap(() => {
                    const error = this.store.selectSnapshot(EnrollmentState.error);
                    const enrollment = this.store.selectSnapshot(EnrollmentState.enrollment);

                    if (enrollment && !error) {
                        this.goToStep(EnrollmentWizardStep.ResendingSuccessMessage);

                        return;
                    }

                    this.goToStep(EnrollmentWizardStep.SendingError);
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }
}
