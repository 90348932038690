import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LandingPageLibModule } from "@dtm-frontend/landing-page-lib";
import { ENROLLMENT_ENDPOINTS, EnrollmentModule } from "@dtm-frontend/landing-page-lib/enrollment";
import { KEYCLOAK_CONFIG, SharedAuthModule } from "@dtm-frontend/shared/auth";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import {
    I18nRootModule,
    LANGUAGE_CONFIGURATION,
    LanguageCode,
    TranslationHelperService,
    getTranslocoInlineLoader,
} from "@dtm-frontend/shared/ui/i18n";
import { VERSION_DATA_ENDPOINTS, VersionModule } from "@dtm-frontend/shared/ui/version";
import { ACCESSIBILITY_STATEMENT_URL, LOCAL_STORAGE, Logger, LoggerModule, TERMS_OF_USE_URL } from "@dtm-frontend/shared/utils";
import { TRANSLOCO_SCOPE, TranslocoService } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsStoragePluginModule, StorageOption } from "@ngxs/storage-plugin";
import { NgxsModule, Store } from "@ngxs/store";
import { first, tap } from "rxjs/operators";
import { LandingPageEnvironment } from "../environments/environment.model";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ActionsHandler } from "./services/actions.handler";
import { initialAppSetup } from "./utils/initial-app-setup";

Logger.initialize("https://63f2512ea543657c87e6e4cb56eefbd8@sentry.pansa.cloud/8");

export function createAppModule(environment: LandingPageEnvironment) {
    @NgModule({
        declarations: [AppComponent],
        imports: [
            NgxsModule.forRoot([], {
                // NOTE: must be first because of https://github.com/ngxs/store/issues/375
                developmentMode: !environment.production,
            }),
            AppRoutingModule,
            BrowserModule,
            BrowserAnimationsModule,
            EnrollmentModule.forRoot(),
            HttpClientModule,
            MatButtonModule,
            MatCardModule,
            MatFormFieldModule,
            MatInputModule,
            NgxsStoragePluginModule.forRoot({
                key: ["ui"],
                storage: StorageOption.LocalStorage,
            }),
            NgxsReduxDevtoolsPluginModule.forRoot({
                disabled: environment.production,
                name: "LandingPage",
            }),
            LetModule,
            PushModule,
            ReactiveFormsModule,
            LoggerModule.forRoot(environment.name, !environment.production),
            SharedAuthModule.forRoot({
                bearerExcludedUrls: [...environment.bearerExcludedUrls, ""],
            }),
            I18nRootModule.forRoot({
                developmentMode: !environment.production,
                ...LANGUAGE_CONFIGURATION,
            }),
            SharedUiModule,
            LandingPageLibModule,
            VersionModule.forRoot(),
        ],
        providers: [
            {
                provide: ENROLLMENT_ENDPOINTS,
                useValue: environment.endpoints,
            },
            {
                provide: TERMS_OF_USE_URL,
                useValue: environment.endpoints.termsOfUse,
            },
            {
                provide: ACCESSIBILITY_STATEMENT_URL,
                useValue: environment.routes.accessibilityStatementUrl,
            },
            {
                provide: VERSION_DATA_ENDPOINTS,
                useValue: environment.versionDataEndpoints,
            },
            {
                provide: APP_INITIALIZER,
                useFactory: initialAppSetup,
                deps: [ActionsHandler, Store, TranslocoService],
                multi: true,
            },
            {
                provide: TRANSLOCO_SCOPE,
                multi: true,
                useValue: {
                    scope: "landingPage",
                    loader: getTranslocoInlineLoader((language: LanguageCode) => import(`../assets/i18n/${language}.json`)),
                },
            },
            { provide: LOCAL_STORAGE, useValue: localStorage },
            {
                provide: KEYCLOAK_CONFIG,
                useValue: environment.keycloakConfig,
            },
        ],
        bootstrap: [AppComponent],
    })
    class AppModule {
        constructor(translocoHelper: TranslationHelperService, titleService: Title) {
            translocoHelper
                .waitForTranslation("landingPage.appTitle")
                .pipe(first(), tap(titleService.setTitle.bind(titleService)))
                .subscribe();
        }
    }

    return AppModule;
}
