import { Injectable } from "@angular/core";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { Action, createSelector, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, finalize, Observable } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { EnrollmentAPIService } from "../services/enrollment-api.service";
import { Enrollment, EnrollmentCapabilities, EnrollmentError, EnrollmentErrorType, EnrollmentFeatures } from "../services/models";
import { EnrollmentActions } from "./enrollment.actions";

export interface EnrollmentStateModel {
    isProcessing: boolean;
    error: EnrollmentError | undefined;
    enrollment: Enrollment | undefined;
    capabilities: EnrollmentCapabilities | undefined;
}

const defaultState: EnrollmentStateModel = {
    error: undefined,
    isProcessing: false,
    enrollment: undefined,
    capabilities: undefined,
};

@State<EnrollmentStateModel>({
    name: "enrollment",
    defaults: defaultState,
})
@Injectable()
export class EnrollmentState {
    constructor(private readonly enrollmentApi: EnrollmentAPIService, private readonly translationHelperService: TranslationHelperService) {
        if (enrollmentApi === undefined) {
            throw new Error("Initialize EnrollmentModule with .forRoot()");
        }
    }

    @Selector()
    public static error(state: EnrollmentStateModel): EnrollmentError | undefined {
        return state.error;
    }

    @Selector()
    public static enrollment(state: EnrollmentStateModel): Enrollment | undefined {
        return state.enrollment;
    }

    @Selector()
    public static isProcessing(state: EnrollmentStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static capabilities(state: EnrollmentStateModel): EnrollmentCapabilities | undefined {
        return state.capabilities;
    }

    public static isFeatureAvailable(feature: EnrollmentFeatures) {
        return createSelector([EnrollmentState], (state: EnrollmentStateModel) =>
            !state.capabilities?.features ? false : state.capabilities.features.includes(feature)
        );
    }

    @Action(EnrollmentActions.Enroll, { cancelUncompleted: true })
    public enroll(context: StateContext<EnrollmentStateModel>, action: EnrollmentActions.Enroll): Observable<Enrollment> {
        context.patchState({
            isProcessing: true,
            error: undefined,
            enrollment: undefined,
        });

        return this.enrollmentApi.enroll(action.payload, this.translationHelperService.getActiveLocale()).pipe(
            tap((result: Enrollment) => context.patchState({ enrollment: result, isProcessing: false })),
            catchError((error) => {
                context.patchState({ error, isProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(EnrollmentActions.ResendConfirmationEmail, { cancelUncompleted: true })
    public resendConfirmationEmail(context: StateContext<EnrollmentStateModel>): Observable<EnrollmentStateModel> {
        context.patchState({
            isProcessing: true,
            error: undefined,
        });

        return this.enrollmentApi.resendConfirmationEmail(context.getState().enrollment).pipe(
            map(() => context.patchState({ isProcessing: false })),
            catchError((error) => {
                context.patchState({ error, isProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(EnrollmentActions.ResendConfirmationCode, { cancelUncompleted: true })
    public resendConfirmationCode(context: StateContext<EnrollmentStateModel>): Observable<EnrollmentStateModel> {
        context.patchState({
            isProcessing: true,
            error: undefined,
        });

        return this.enrollmentApi.resendConfirmationCode(context.getState().enrollment).pipe(
            map(() => context.patchState({ isProcessing: false })),
            catchError((error) => {
                context.patchState({ error, isProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(EnrollmentActions.UpdatePhoneNumber, { cancelUncompleted: true })
    public updatePhoneNumber(
        context: StateContext<EnrollmentStateModel>,
        action: EnrollmentActions.UpdatePhoneNumber
    ): Observable<EnrollmentStateModel> {
        context.patchState({
            isProcessing: true,
            error: undefined,
        });

        return this.enrollmentApi
            .updatePhoneNumber(action.newPhoneNumber, context.getState().enrollment, this.translationHelperService.getActiveLocale())
            .pipe(
                map((result: Enrollment) => context.patchState({ enrollment: result })),
                catchError((error) => {
                    if (error?.type === EnrollmentErrorType.ConfirmationCodeRateLimitReached) {
                        context.patchState({ error });

                        return EMPTY;
                    }

                    context.patchState({ error });

                    return EMPTY;
                }),
                finalize(() => context.patchState({ isProcessing: false }))
            );
    }

    @Action(EnrollmentActions.ConfirmEnrollment, { cancelUncompleted: true })
    public confirmEnrollment(
        context: StateContext<EnrollmentStateModel>,
        action: EnrollmentActions.ConfirmEnrollment
    ): Observable<EnrollmentStateModel> {
        context.patchState({
            isProcessing: true,
            error: undefined,
            enrollment: undefined,
        });

        return this.enrollmentApi.confirmEnrollment(action.confirmationCode, action.enrollmentId).pipe(
            map((result: Enrollment) => context.patchState({ enrollment: result, error: undefined, isProcessing: false })),
            catchError((error) => {
                context.patchState({ enrollment: undefined, error, isProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(EnrollmentActions.Register, { cancelUncompleted: true })
    public register(context: StateContext<EnrollmentStateModel>, action: EnrollmentActions.Register): Observable<void> {
        context.patchState({
            isProcessing: true,
            error: undefined,
        });

        return this.enrollmentApi
            .register(
                action.password,
                action.confirmationCode,
                action.enrollmentId,
                action.firstName,
                action.lastName,
                action.nationality,
                new Date(action.dateOfBirth),
                this.translationHelperService.getActiveLocale()
            )
            .pipe(
                switchMap(() => {
                    context.patchState({ enrollment: undefined, error: undefined, isProcessing: false });

                    return context.dispatch(new EnrollmentActions.GoToApp());
                }),
                catchError((error) => {
                    context.patchState({ error, isProcessing: false });

                    return EMPTY;
                })
            );
    }

    @Action(EnrollmentActions.GetCapabilities)
    public getCapabilities(context: StateContext<EnrollmentStateModel>): Observable<EnrollmentCapabilities> {
        context.patchState({
            isProcessing: true,
            error: undefined,
        });

        return this.enrollmentApi.getCapabilities().pipe(
            tap((result: EnrollmentCapabilities) => context.patchState({ capabilities: result, error: undefined, isProcessing: false })),
            catchError((error) => {
                context.patchState({ capabilities: undefined, error, isProcessing: false });

                return EMPTY;
            })
        );
    }
}
