import { Alpha3CountryCode, PhoneNumber } from "@dtm-frontend/shared/ui";
import { EnrollmentData } from "../services/models";

export namespace EnrollmentActions {
    export class Enroll {
        public static readonly type = "[Enrollment] Enroll";
        constructor(public payload: EnrollmentData) {}
    }
    export class Register {
        public static readonly type = "[Enrollment] Register account";
        constructor(
            public password: string,
            public confirmationCode: string,
            public enrollmentId: string,
            public firstName: string,
            public lastName: string,
            public nationality: Alpha3CountryCode,
            public dateOfBirth: string
        ) {}
    }

    export class ConfirmEnrollment {
        public static readonly type = "[Enrollment] Confirm enrollment";
        constructor(public enrollmentId: string, public confirmationCode: string) {}
    }

    export class ResendConfirmationEmail {
        public static readonly type = "[Enrollment] Resend confirmation e-mail";
    }

    export class ResendConfirmationCode {
        public static readonly type = "[Enrollment] Resend confirmation code";
    }

    export class UpdatePhoneNumber {
        public static readonly type = "[Enrollment] Update phone number";
        constructor(public newPhoneNumber: PhoneNumber) {}
    }

    export class GoToLoginPage {
        public static readonly type = "[Enrollment] Go to Login page";
    }

    export class GoToFAQPage {
        public static readonly type = "[Enrollment] Go to FAQ page";
    }

    export class GoToUAVRulesPage {
        public static readonly type = "[Enrollment] Go to UAV rules page";
    }

    export class ShowTermsOfService {
        public static readonly type = "[Enrollment] Show terms of service";
    }

    export class GoToApp {
        public static readonly type = "[Enrollment] Go to Application";
    }

    export class GetCapabilities {
        public static readonly type = "[Enrollment] Get capabilities";
    }
}
