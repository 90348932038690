<ng-container
    *ngrxLet="{ currentStep: currentStep$, enrollmentError: enrollmentError$, minUserAge: minUserAge$ } as vm"
    [ngSwitch]="vm.currentStep"
>
    <dtm-lp-lib-enrollment-wizard-form-step
        *ngSwitchCase="EnrollmentWizardStep.EnrollmentForm"
        (enroll)="enroll($event)"
        (login)="login()"
        [isProcessing]="isProcessing$ | ngrxPush"
        [enrollmentError]="vm.enrollmentError"
        [minUserAge]="vm.minUserAge"
    ></dtm-lp-lib-enrollment-wizard-form-step>

    <dtm-lp-lib-enrollment-wizard-message
        *ngSwitchDefault
        [currentStep]="vm.currentStep"
        [emailAddress]="(enrollment$ | ngrxPush)?.email"
        [enrollmentError]="vm.enrollmentError"
        (resendEmail)="resendEmail()"
        (goToRegistration)="goToStep(EnrollmentWizardStep.EnrollmentForm)"
    ></dtm-lp-lib-enrollment-wizard-message>
</ng-container>
